import React, { useEffect } from "react";
import CareerCard from "./career-card";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useLocation } from "react-router-dom";
import gsap from "gsap";
import { useSelector } from "react-redux";
import programmerImg from "../../assets/programmer-img.webp";

const JobsCareer = () => {
  const location = useLocation();

  const ShowSection = () => {
    const options = {
      loop: false,
      center: false,
      items: 3,
      margin: 15,
      autoplay: true,
      dots: false,
      autoplayTimeout: 8500,
      smartSpeed: 450,
      nav: true,
      responsive: {
        0: {
          items: 1,
        },

        667: {
          items: 2,
        },

        1024: {
          items: 3,
        },

        1200: {
          items: 3,
        },
      },
    };
    // const cards = useSelector((state)=>state.user.jobs);
    const cards = [
      // {
      //   heading: 'Executive Assistant',
      //   picture: '../assets/executive-assistant-img.webp'
      // },
      // {
      //   heading: 'Programmer',
      //   picture: '../assets/programmer-img.webp'
      // },
      {
        heading: "Unity Developer",
        picture: programmerImg,
      },
    ];
    useEffect(() => {
      const section = document.getElementsByClassName(
        "job-career-wrapper-container"
      )[0];
      const animateSection = () => {
        const bounding = section.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
          gsap.to(section, { rotationX: 0, duration: 0.8 });
        } else {
          gsap.to(section, { rotationX: 90, duration: 0.8 });
        }
      };
      animateSection();
      window.addEventListener("scroll", animateSection);
      return () => {
        window.removeEventListener("scroll", animateSection);
      };
    }, []);
    return (
      <section className="job-career-wrapper">
        <div className="container job-career-wrapper-container">
          <h2 className="text-center text-white">Jobs and Career</h2>

          <div className="job-career-slider">
            {cards.length > 0 && (
              <OwlCarousel className="owl-carousel owl-theme" {...options}>
                {cards.map((card, i) => (
                  <CareerCard key={i} card={card} />
                ))}
              </OwlCarousel>
            )}

            <div className="mt-5 text-center">
              <Link to="/career" className="white-btn">
                View All Careers <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  };
  return !location.pathname.includes("/career") && <ShowSection />;
};

export default JobsCareer;
