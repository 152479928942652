import React, { useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import AboutUs from "./components/About";
import CopyRights from "./components/CopyRights";
import './style.css';
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import FAQ from "./components/Faq.js";
import Career from "./components/Career/index.js";
import ContactUs from "./components/Contact/index.js";
import JobsCareer from "./components/Shared/jobsCareer.js";
import OurServiceCards from "./components/OurServiceCards/index.js";
import ScrollToTop from "./scrollToTop.js";
import ApplyPage from "./components/Apply/index.js";
import PrivacyPolicies from "./components/PrivacyPolicies/index.js";
import TermsConditions from "./components/TermsConditions/index.js";
import TrackerInfo from "./components/TrackerInfo/index.js";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "./LoadingSpinner";
import './spinner.css';
import {useDispatch} from 'react-redux';
const App=()=>{
  
  const AllRoutes = () =>
    useRoutes([
      { path: "/", element: <Home /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/apply", element: <ApplyPage /> },
      { path: "/cards", element: <OurServiceCards /> },  
      { path: "/cards/:id", element: <OurServiceCards /> },
      { path: "/career", element: <Career /> },
      { path: "/career/:id", element: <Career /> },
      { path: "/contact-us", element: <ContactUs /> },
      { path: "/faq", element: <FAQ /> },
      { path: "/privacy-policies", element: <PrivacyPolicies /> },
      { path: "/terms-conditions", element: <TermsConditions /> },
      { path: "/tracker-info", element: <TrackerInfo /> },
      { path: "*", element: <Navigate to="/" replace /> },
    ]);
    
  return (
    <BrowserRouter>
      <div className="App">
      <ScrollToTop />
        <ToastContainer limit={1}/>
        <LoadingSpinner/>
        <Header/>
        <AllRoutes/>
        <JobsCareer/>
        <Footer/>
        <CopyRights/>
      </div>
    </BrowserRouter>
  );
}

export default App;
