import gsap from 'gsap';
import React, { useRef, useEffect } from 'react';
import { Link } from 'react-scroll';



const Banner = () => {
  const spansRef = useRef(null);
  const sectionRef = useRef(null);

  // useEffect(() => {
  //   const section = sectionRef.current;

  //   const animateSection = () => {
  //     const bounding = section.getBoundingClientRect();
  //     const windowHeight = window.innerHeight;
  //     if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
  //       gsap.to(section, { rotationX: 0, duration: 0.8 });
  //     } else {
  //       gsap.to(section, { rotationX: 90, duration: 0.8 });
  //     }
  //   };

  //   animateSection();
  //   window.addEventListener('scroll', animateSection);

  //   return () => {
  //     window.removeEventListener('scroll', animateSection);
  //   };
  // }, []);

  useEffect(() => {
    const spans = spansRef.current.querySelectorAll('.word span');

    const addListeners = (span, idx) => {
      span.addEventListener('click', (e) => {
        e.target.classList.add('active');
      });
      span.addEventListener('animationend', (e) => {
        e.target.classList.remove('active');
      });

      // Initial animation
      setTimeout(() => {
        span.classList.add('active');
      }, 750 * (idx + 1));
    };

    spans.forEach(addListeners);
  }, []);
  return (
    <div className='position-relative main-banner-video-wrap' ref={sectionRef}>
      {/* <video height={'100%'} width={'100%'} autoPlay muted loop src="../assets/main-banner-video.mp4"></video> */}
      <section className='kids-money-wrapper'>
        <div className='container kids-money-wrapper-container text-center text-white' ref={spansRef}>
          <div className='kids-money-des-'>
            <h1 className='waviy'>
              <span style={{ '--i': 1 }}>E</span>
              <span style={{ '--i': 2 }}>m</span>
              <span style={{ '--i': 3 }}>p</span>
              <span style={{ '--i': 4 }}>O</span>
              <span style={{ '--i': 5 }}>w</span>
              <span style={{ '--i': 6 }}>e</span>
              <span style={{ '--i': 7 }}>r</span>
              <span style={{ '--i': 8 }}>i</span>
              <span style={{ '--i': 9 }}>n</span>
              <span style={{ '--i': 10 }}>g</span>
              &nbsp;
              <span style={{ '--i': 11 }}>K</span>
              <span style={{ '--i': 12 }}>i</span>
              <span style={{ '--i': 13 }}>d</span>
              <span style={{ '--i': 14 }}>s</span>
              &nbsp;
              <span style={{ '--i': 15 }}>&</span>
              &nbsp;
              <span style={{ '--i': 16 }}>M</span>
              <span style={{ '--i': 17 }}>o</span>
              <span style={{ '--i': 18 }}>n</span>
              <span style={{ '--i': 19 }}>e</span>
              <span style={{ '--i': 20 }}>y</span>
            </h1>
            <p className='my-4'>Bank’d is pioneering the intersection of finance and technology, introducing an immersive 3D banking platform where real world money meets virtual world exploration. Bank’d aims to transform financial products for the next generation, making them engaging, interactive, and practical.</p>
            <a href='/apply' className='white-btn'>Register Interest</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Banner;