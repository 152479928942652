import React from "react";
// import ServiceCardBelowSection from '../Shared/serviceCardBelowSection';
import Banner from "../Shared/banner";
import Questions from "./questions";
import faqMainBg from "../../assets/faq-main-bg.webp";

const FAQ = () => {
  const data = {
    title: "FAQ’s",
    bgImage: faqMainBg,
  };
  return (
    <div>
      <Banner data={data} />
      <Questions />
      {/* <ServiceCardBelowSection /> */}
    </div>
  );
};

export default FAQ;
