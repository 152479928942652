import React from "react";
import { Link } from "react-router-dom";
import benefitIcon1 from "../../assets/benefit-icon1.webp";
import benefitIcon2 from "../../assets/benefit-icon2.webp";
import benefitIcon3 from "../../assets/benefit-icon3.webp";
import benefitIcon4 from "../../assets/benefit-icon4.webp";
import benefitIcon5 from "../../assets/benefit-icon5.webp";
import benefitIcon6 from "../../assets/benefit-icon6.webp";
import benefitIcon7 from "../../assets/benefit-icon7.webp";
import benefitIcon8 from "../../assets/benefit-icon8.webp";
import serviceCardImg1 from "../../assets/service-card-img1.webp";
import serviceCardImg2 from "../../assets/service-card-img2.webp";
import goolgeIcon from "../../assets/goolge-icon.webp";

const CardsDescription = () => {
  return (
    <section className="CardsDescription-bg-wrap">
      <div className="container text-white">
        <div className="row pb-5">
          <div className="col-md-6">
            <div className="service-card-detail-wrap">
              <h2>
                BANK’D <br />
                Debit Card
              </h2>
              <ul className="debit-card-benefits-list">
                <li>
                  <img src={benefitIcon1} alt="" className="img-fluid" />
                  <b>Debit Cards</b> & Personal BSB’s
                </li>
                <li>
                  <img src={benefitIcon2} alt="" className="img-fluid" />
                  <b>Real Time Alerts</b> on all activity
                </li>
                <li>
                  <img src={benefitIcon3} alt="" className="img-fluid" />
                  <b>Complete Card Control</b> at all times
                </li>
                <li>
                  <img src={benefitIcon4} alt="" className="img-fluid" />
                  <b>Instant Transfers</b> to parent and child wallet
                </li>
                <li>
                  <img src={benefitIcon5} alt="" className="img-fluid" />
                  <b>Spending Limits,</b> daily & weekly
                </li>
                <li>
                  <img src={benefitIcon6} alt="" className="img-fluid" />
                  <b>Merchant Restrictions</b> Worldwide
                </li>
                <li>
                  <img src={benefitIcon7} alt="" className="img-fluid" />
                  <b>Geographical Limits</b>
                </li>
                <li>
                  <img src={benefitIcon8} alt="" className="img-fluid" />
                  <b>ATM Compatible</b> when approved
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <img src={serviceCardImg1} alt="" className="img-fluid" />
          </div>
        </div>

        <div className="row pt-5 align-items-center">
          <div className="col-lg-6 col-md-5 digital-wallet-col1">
            <img src={serviceCardImg2} alt="" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-md-7 digital-wallet-col1">
            <div className="digital-wallets-detail-wrap">
              <h2>Digital Wallets</h2>
              <h5 className="">Compatible with Apple & Google Pay</h5>
              <div className="digital-wallets-btns">
                <Link to="javascript:void(0)" className="apple-pay-btn">
                  <i className="bi bi-apple"></i>
                  <span>Apple Pay</span>
                </Link>
                <Link to="javascript:void(0)" className="gpay-btn">
                  <img src={goolgeIcon} className="img-fluid" />
                  <span>Gpay</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardsDescription;
