import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get_in_touch } from '../../reduxData/user/userAction';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from './PhoneNumber';
const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name:null,
    email:null,
    phone:null,
    description:null
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    name:null,
    email:null,
    phone:null,
    description:null
  });
  const emailRegrex=/\S+@\S+\.\S+/;
  const handleChange=(value,label)=>{
    errors[label]=(!value)?`required`:(((label==='email') && !emailRegrex.test(value)) || ((label==='phone') && !isValidPhoneNumber(value)))?'invalid':null;
    setErrors({...errors});
    setFormData({...formData,[label]:value});
  }
  const checkErrors=()=>{
    let err=false;
    const entriesArray = Object.entries(formData);
    entriesArray.forEach(([key, value]) => {
      err=(!value || ((key==='email') && !emailRegrex.test(value)) || ((key==='phone') && !isValidPhoneNumber(value)))?true:false;
      handleChange(value, key);
    });
    return err;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if(checkErrors()){
      return;
    }else{
      const d=new FormData();
      d.append('name',formData.name);
      d.append('email',formData.email);
      d.append('phone',formData.phone);
      d.append('description',formData.description);
      get_in_touch(formData,dispatch,navigate)
    } 
  };

  return (
    <section className='get-in-touch-wrapper'>
      <div className='container'>
        <form className='get-in-touch-form'>
          <h2 className='text-black text-center mb-4'>Get in Touch</h2>
          <div className='row'>
            <div className='col-md-6'>
              <div className='mb-3'>
                <label for="name">Name</label>
                <input type='text' defaultValue={formData.name} className='form-control' placeholder='Enter Name' onChange={(e)=>handleChange(e.target.value,'name')}/>
                {errors.name && (
                  <p style={{ color: "red" }}>
                    Name is required.
                  </p>
                )}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='mb-3'>
                <label for="name">Phone</label>
                <br/>
                <PhoneNumberInput
                  name="phoneNumber"
                  className="form-control"
                  onChange={(e) => handleChange(e, 'phone')}
                />
                {errors.phone && (
                  <p style={{ color: "red" }}>
                    Phone is {errors.phone}.
                  </p>
                )}
              </div>
            </div>
            <div className='col-md-12'>
              <div className='mb-3'>
                <label for="name">Email</label>
                <input type='email' defaultValue={formData.email} className='form-control' placeholder='Enter Email' onChange={(e)=>handleChange(e.target.value,'email')}/>
                {errors.email && (
                  <p style={{ color: "red" }}>
                    Email is {errors.email}.
                  </p>
                )}
              </div>
            </div>

            <div className='col-md-12'>
              <div className=''>
                <label for="name">Description</label>
                <textarea defaultValue={formData.description} className="form-control" placeholder="Description..." rows="4" cols="50" onChange={(e)=>handleChange(e.target.value,'description')}></textarea>
                {errors.description && (
                  <p style={{ color: "red" }}>
                    Description is required.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className='contact-us-btns'>
            <Link to='javascript:void(0)' className='contact-submit-btn' onClick={(e)=>handleSubmit(e)}>Submit</Link>
            <Link to='/' className='contact-Cancel-btn'>Cancel</Link>
          </div>
        </form>
      </div>
    </section>
  )
}

export default GetInTouch;