import React, { useEffect, useState } from "react";
import Banner from "../Shared/banner";
import CardsList from "./cards-list";
import { useParams } from "react-router-dom";
import JobDetailContent from "./job-detail-content";
import { useSelector } from "react-redux";
import programmerImg from "../../assets/programmer-img.webp";
import JobsAndCareerBanner from "../../assets/Jobs-and-career-banner.webp";

const Career = () => {
  const data = {
    title: "Jobs and Career",
    bgImage: JobsAndCareerBanner,
  };
  // const cards = useSelector((state)=>state.user.jobs);
  const cards = [
    // {
    //   heading: 'Executive Assistant',
    //   picture: '../assets/executive-assistant-img.webp'
    // },
    // {
    //   heading: 'Programmer',
    //   picture: '../assets/programmer-img.webp'
    // },
    {
      heading: "Unity Developer",
      picture: programmerImg,
    },
  ];
  const { id } = useParams();
  return (
    <div className="jobs-and-career-main-div">
      <Banner data={data} />
      {id ? (
        <JobDetailContent card={cards.find((r) => r.id === id)} />
      ) : (
        <CardsList cards={cards} />
      )}
    </div>
  );
};

export default Career;
