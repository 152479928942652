import gsap from "gsap";
import React, { useEffect } from "react";
import visualEngagementTabImg from "../../assets/visualEngagement-tab-img.webp";

const VisualEngagement = () => {
  useEffect(() => {
    const section = document.getElementsByClassName(
      "visual-engagement-wrap-container"
    )[0];
    const animateSection = () => {
      const bounding = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
        gsap.to(section, { rotationY: 0, duration: 0.8 });
      } else {
        gsap.to(section, { rotationY: 90, duration: 0.8 });
      }
    };
    animateSection();
    window.addEventListener("scroll", animateSection);
    return () => {
      window.removeEventListener("scroll", animateSection);
    };
  }, []);
  return (
    <section className="visual-engagement-wrap">
      <div className="container-fluid visual-engagement-wrap-container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="visual-engagement-des- text-white">
              <h2>Visual Engagement</h2>
              <p className="my-3">
                Gone are the boring text rows of bank deposits and charges. Kids
                can see how much money they are earning and “burning” from their
                bank’s basement.
              </p>
              <a href="/apply" className="white-btn">
                Register Interest
              </a>
            </div>
          </div>
          <div className="col-md-6 VisualEngagement-mob-img pe-0">
            <img
              src={visualEngagementTabImg}
              alt="visualEngagement"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisualEngagement;
