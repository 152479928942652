import React from "react";
import Banner from "../Shared/banner";
import ServiceCardBelowSection from "../Shared/serviceCardBelowSection";
import GetInTouch from "../Shared/get-in-touch";
import Contacts from "./contacts";
import contactUsBg from "../../assets/contact-us-bg.webp";

const ContactUs = () => {
  const data = {
    title: "Contact Us",
    bgImage: contactUsBg,
  };
  return (
    <div>
      <Banner data={data} />
      <Contacts />
      <GetInTouch />
      <ServiceCardBelowSection />
    </div>
  );
};

export default ContactUs;
