import React from 'react'
import CareerCard from '../Shared/career-card';

const CardsList = ({ cards }) => {

  return (
    <section className='career-card-bg-wrapper'>
      <div className='container'>
        <div className='row justify-content-center'>
          {cards.map((card, i) =>
            <div className='col-xl-3 col-md-3 col-6'>
              <div className='career-card-wrap'>
                <CareerCard key={i} card={card} />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default CardsList;