import React, { useEffect } from 'react'
import Banner from './banner';
import ExploreKids from './exploreKids';
import BoringKids from './boringKids';
import MakeMoney from './makeMoney';
import ServiceCards from './serviceCards';
import ServiceCardBelowSection from '../Shared/serviceCardBelowSection';
import VisualEngagement from './visualEngagement';
// import ComingSoon from './comingsoon';
import GrowingMoney from './growingMoney';
import TotalControl from './totalControl';
import { useDispatch } from 'react-redux';
import { get_cards, get_jobs } from '../../reduxData/user/userAction';
const Home = () => {
  const dispatch=useDispatch();
  
  // useEffect(()=>{
  //   get_cards(dispatch);
  //   get_jobs(dispatch);
  // },[])
  return (
    <div>
      <Banner/>
      <ExploreKids/>
      <MakeMoney/>
      <BoringKids/>
      <ServiceCards/>
      <ServiceCardBelowSection/>
      <GrowingMoney/>
      <VisualEngagement/>
      {/* <ComingSoon/> */}
      <TotalControl/>
    </div>
  )
}

export default Home;

