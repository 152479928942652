import React from "react";
import comingSoonCardBoy from "../../assets/coming-soon-card-boy.webp";

const ComingSoonCards = () => {
  return (
    <section className="card-coming-soon-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="row card-coming-soon-bg2">
              <div className="col-md-6 text-white">
                <div className="card-coming-soon-info-wrap">
                  <h6>Coming Soon</h6>
                  <h3 className="my-4">
                    Bank’d Card <br /> Tracker
                  </h3>
                  <a href="#" className="white-btn">
                    Register Interest
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="comingsoon-cardboy-img-wrap position-relative">
                  <img
                    src={comingSoonCardBoy}
                    alt="coming-soon-card-boy"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComingSoonCards;
