import { GET_CARDS, GET_JOBS } from "./userTypes";
const initialState = {
  jobs: [],
  cards: [],
  cardTotal: 0,
  jobsTotal: 0,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARDS:
      return {
        ...state,
        cards: action.payload.data,
        cardTotal: action.payload.total,
      };
    case GET_JOBS:
      return {
        ...state,
        jobs: action.payload.data,
        jobTotal: action.payload.total,
      };  
    default:
      return state;
  }
};

export default userReducer;