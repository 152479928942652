import React from 'react'
import Banner from '../Shared/banner';
import Content from './content';
import PrivacyPolicyBg from "../../assets/privacy-policy-bg.webp";

const PrivacyPolicies = () => {
  const data = {
    title: "Privacy Policy",
    bgImage: PrivacyPolicyBg
  }
  return (
    <div>
      <Banner data={data} />
      <Content />
    </div>
  )
}

export default PrivacyPolicies;

