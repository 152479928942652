import React from 'react'
import CareerCard from '../Shared/career-card';

const Contacts = () => {
  return (
    <section className='contact-address-wrapper'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 aus-col'>
            <div className='australian-address-col'>
              <h4 className='text-white'>Australia</h4>
              <div className='row'>
                <div className='col-lg-6 col-md-6'>
                  <div className='contact-detail-container'>
                    <i className="bi bi-telephone-fill text-white"></i>
                    <div>
                      <h5 className='text-white'>Call us</h5>
                      <p className='text-white'>+61 7 5555 2755</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6'>
                  <div className='contact-detail-container'>
                    <i className="bi bi-clock-fill text-white"></i>
                    <div>
                      <h5 className='text-white'>Time</h5>
                      <p className='text-white'>9AM – 5PM (AEST)</p>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6 col-md-12'>
                  <div className='contact-detail-container'>
                    <i className="bi bi-geo-alt-fill text-white"></i>
                    <div>
                      <h5 className='text-white'>Address</h5>
                      <p className='text-white'>Suite 303, Level 3, 130 Bundall Road, Bundall, Queensland, 4217, Australia</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                  <div className='contact-detail-container'>
                    <i className="bi bi-envelope-fill text-white"></i>
                    <div>
                      <h5 className='text-white'>Email</h5>
                      <p className='text-white'>admin@bankd.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 us-col'>
            <div className='usa-address-col'>
              <h4 className='text-white'>United States of America</h4>
              <div className='row'>
                <div className='col-lg-6 col-md-6'>
                  <div className='contact-detail-container'>
                    <i className="bi bi-telephone-fill text-white"></i>
                    <div>
                      <h5 className='text-white'>Call us</h5>
                      <p className='text-white'>+1 (310) 995 3838</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6'>
                  <div className='contact-detail-container'>
                    <i className="bi bi-clock-fill text-white"></i>
                    <div>
                      <h5 className='text-white'>Time</h5>
                      <p className='text-white'>9AM – 5PM (PST)</p>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6 col-md-12'>
                  <div className='contact-detail-container'>
                    <i className="bi bi-geo-alt-fill text-white"></i>
                    <div>
                      <h5 className='text-white'>Address</h5>
                      <p className='text-white'>12100 Wilshire Boulevard, Level 8, Los Angeles, CA 90025 United States of America</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                  <div className='contact-detail-container'>
                    <i className="bi bi-envelope-fill text-white"></i>
                    <div>
                      <h5 className='text-white'>Email</h5>
                      <p className='text-white'>admin@bankd.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Contacts;