import React from "react";
import Banner from "../Shared/banner";
import ApplyForm from "./ApplyForm";
import appleBgWrapper from "../../assets/apply-bg-wrapper.webp";

const ApplyPage = () => {
  const data = {
    title: "Apply",
    bgImage: appleBgWrapper,
  };
  return (
    <div>
      <Banner data={data} />
      <ApplyForm />
    </div>
  );
};

export default ApplyPage;
