import React from 'react'

const CopyRights = () => {
  return (
    <section className='copyright-wrapper'>
      <div className='container'>
        <p className='text-center mb-0'>Copyright © 2024 <b><u>SB USA Inc (USA) & Kids Money App Australia Pty Ltd (AUS)</u></b>    |    All rights reserved.</p>
      </div>
    </section>
  )
}

export default CopyRights;