import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import TotalControlImg from "../../assets/total-control-img.webp";

const TotalControl = () => {
  useEffect(() => {
    const playAnimation = () => {
      gsap.registerPlugin(ScrollTrigger);
      const tl = gsap.timeline();
      tl.to(".my-image", {
        rotationY: 30,
        duration: 2,
        ease: "power2.inOut",
      });

      tl.to(".my-image", {
        rotationY: 0,
        duration: 2,
        ease: "power2.inOut",
        onComplete: () => {
          clearInterval(intervalID);
          playAnimation();
        },
      });
    };

    playAnimation();
    const intervalID = setInterval(() => {
      playAnimation();
    }, 4000);
    return () => clearInterval(intervalID);
  }, []);
  useEffect(() => {
    const section = document.getElementsByClassName(
      "total-control-wrapper-container"
    )[0];
    const animateSection = () => {
      const bounding = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
        gsap.to(section, { rotationY: 0, duration: 0.8 });
      } else {
        gsap.to(section, { rotationY: 90, duration: 0.8 });
      }
    };
    animateSection();
    window.addEventListener("scroll", animateSection);
    return () => {
      window.removeEventListener("scroll", animateSection);
    };
  }, []);
  return (
    <section className="total-control-wrapper">
      <div className="container total-control-wrapper-container">
        <div className="row">
          <div className="col-md-6">
            <img src={TotalControlImg} alt="" className="img-fluid my-image" />
          </div>
          <div className="col-md-6">
            <div className="total-control-des- text-white text-end">
              <h2>Total Control</h2>
              <p className="my-3">
                All you helicopter parents can keep a close eye on your kids
                accounts, ensure they are making the right decisions about
                spending, saving, earning and investing. All accounts are held
                by the parent as custodian for the child and every action is
                approved by the parent.
              </p>
              <Link to="/apply" className="white-btn">
                Register Interest
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TotalControl;
