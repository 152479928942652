import gsap from 'gsap';
import React, { useEffect } from 'react'

const Banner = ({data}) => {
  const containerStyle = {
    backgroundImage: `url(${data?.bgImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    // backgroundAttachment: 'fixed',
    padding: '12rem 0 9rem 0',
  };
  useEffect(() => {
    const section = document.getElementsByClassName("about-us-bg-container")[0];
    const animateSection = () => {
      const bounding = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
        gsap.to(section, { rotationX: 0, duration: 0.8 });
      } else {
        gsap.to(section, { rotationX: 90, duration: 0.8 });
      }
    };
    animateSection();
    window.addEventListener('scroll', animateSection);
    return () => {
      window.removeEventListener('scroll', animateSection);
    };
  }, []);
  return (
    <section className='about-us-bg-wrapper' style={containerStyle}>
      <div className='container about-us-bg-container text-center text-white'>
        <div className='about-us-bg-des-'>
          <h1 className='text-capitalize'>{data?.title}</h1>
        </div>
      </div>
    </section>
  )
}

export default Banner;