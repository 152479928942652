import React from "react";
import { Link, useLocation } from "react-router-dom";
import headerLogo from "../assets/header-bankd-Logo.webp";

const Footer = () => {
  const location = useLocation();
  return (
    <footer
      className={`footer-wrapper ${
        !location.pathname.includes("/career") && "footer-wrapper-margin"
      }`}
    >
      <div className="container">
        <div className="grid-container">
          <div className="footer-logo-container">
            <Link to="/">
              <img src={headerLogo} className="img-fluid" alt="footer-logo" />
            </Link>
          </div>
          <div className="footer-navbar-container">
            <ul>
              <li>
                <Link to="/">
                  <i className="bi bi-chevron-right"></i> Home
                </Link>
              </li>
              <li>
                <Link to="/about-us">
                  <i className="bi bi-chevron-right"></i> About us
                </Link>
              </li>
              {/* <li>
                <Link to="/cards"><i className="bi bi-chevron-right"></i> Cards</Link>
              </li>
              <li>
                <Link to="/career"><i className="bi bi-chevron-right"></i> Career</Link>
              </li> */}
              <li>
                <Link to="/contact-us">
                  <i className="bi bi-chevron-right"></i> Contact us
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-other-link-container">
            <ul>
              <li>
                <Link to="/cards">
                  <i className="bi bi-chevron-right"></i> Cards
                </Link>
              </li>
              <li>
                <Link to="/career">
                  <i className="bi bi-chevron-right"></i> Career
                </Link>
              </li>
              {/* <li>
                <Link to="/terms-conditions"><i className="bi bi-chevron-right"></i> Terms and Policy</Link>
              </li>
              <li>
                <Link to="/privacy-policies"><i className="bi bi-chevron-right"></i> Privacy Policy</Link>
              </li>
              <li>
                <Link to="/Faq"><i className="bi bi-chevron-right"></i> FAQ’s</Link>
              </li> */}
            </ul>
          </div>

          <div className="social-media-container">
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/bankdapp"
                  className="facebook-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-facebook-f"></i> Facebook
                </Link>
              </li>

              <li>
                <Link
                  to="https://www.linkedin.com/company/bankd-app/"
                  className="facebook-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-linkedin"></i>Linkedin
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
