import React from "react";
import Enterpernures from "./enterpernures";
import ServiceCardBelowSection from "../Shared/serviceCardBelowSection";
import Banner from "../Shared/banner";
import aboutUsBg from "../../assets/about-us-bg.webp";

const AboutUs = () => {
  const data = {
    title: "About us",
    bgImage: aboutUsBg,
  };
  return (
    <div className="about-us-page-wrapper">
      <Banner data={data} />
      <Enterpernures />
      <ServiceCardBelowSection />
    </div>
  );
};

export default AboutUs;
