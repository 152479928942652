import React from "react";
import Banner from "../Shared/banner";
import CardsDescription from "./cards-description";
import CardsList from "./cards-list";
import ComingSoonCards from "./ComingSoon";
import { useParams } from "react-router-dom";
import CardsDetailContent from "./card-detail-content";
import { useSelector } from "react-redux";
import OurServiceCardsBg from "../../assets/our-service-card-bg.webp";
import OurServiceSliderImg1 from "../../assets/our-service-slider-img1.webp";
import OurServiceSliderImg2 from "../../assets/our-service-slider-img2.webp";
import OurServiceSliderImg3 from "../../assets/our-service-slider-img3.webp";
import OurServiceSliderImg4 from "../../assets/our-service-slider-img4.webp";
import OurServiceSliderImg5 from "../../assets/our-service-slider-img5.webp";
import OurServiceSliderImg6 from "../../assets/our-service-slider-img6.webp";
import OurServiceSliderImg7 from "../../assets/our-service-slider-img7.webp";
import OurServiceSliderImg8 from "../../assets/our-service-slider-img8.webp";
import OurServiceSliderImg9 from "../../assets/our-service-slider-img9.webp";
import OurServiceSliderImg10 from "../../assets/our-service-slider-img10.webp";

const OurServiceCards = () => {
  const data = {
    title: "Our Cards",
    bgImage: OurServiceCardsBg,
  };

  // const cards = useSelector((state)=>state.user.cards);
  const cards = [
    {
      id: "1",
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg1,
      bgColor: "purpul-slide",
    },
    {
      id: "2",
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg2,
      bgColor: "pink-slide",
    },
    {
      id: "3",
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg3,
      bgColor: "bright-blue-slide",
    },
    {
      id: "4",
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg4,
      bgColor: "pink-slide",
    },
    {
      id: "5",
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg5,
      bgColor: "bright-violet-slide",
    },
    {
      id: "6",
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg6,
      bgColor: "dark-blue-slide",
    },
    {
      id: "7",
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg7,
      bgColor: "light-yellow-slide",
    },
    {
      id: "8",
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg8,
      bgColor: "dark-yellow-slide",
    },
    {
      id: "9",
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg9,
      bgColor: "red-slide",
    },
    {
      id: "10",
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg10,
      bgColor: "dark-bright-blue-slide",
    },
  ];
  const { id } = useParams();

  return (
    <div className="our-cards-main-div">
      <Banner data={data} />
      {id ? (
        <CardsDetailContent card={cards.find((r) => r.id === id)} />
      ) : (
        <>
          <CardsDescription />
          <CardsList cards={cards} />
          <ComingSoonCards />
        </>
      )}
    </div>
  );
};

export default OurServiceCards;
