import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import gsap from "gsap";
import ServiceCard from "../Shared/ServiceCard";
import { useSelector } from "react-redux";
import OurServiceSliderImg1 from "../../assets/our-service-slider-img1.webp";
import OurServiceSliderImg2 from "../../assets/our-service-slider-img2.webp";
import OurServiceSliderImg3 from "../../assets/our-service-slider-img3.webp";
import OurServiceSliderImg4 from "../../assets/our-service-slider-img4.webp";
import OurServiceSliderImg5 from "../../assets/our-service-slider-img5.webp";
import OurServiceSliderImg6 from "../../assets/our-service-slider-img6.webp";
import OurServiceSliderImg7 from "../../assets/our-service-slider-img7.webp";
import OurServiceSliderImg8 from "../../assets/our-service-slider-img8.webp";
import OurServiceSliderImg9 from "../../assets/our-service-slider-img9.webp";
import OurServiceSliderImg10 from "../../assets/our-service-slider-img10.webp";

const ServiceCards = () => {
  // const cards = useSelector((state)=>state.user.cards);
  const cards = [
    {
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg1,
      bgColor: "purpul-slide",
    },
    {
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg2,
      bgColor: "pink-slide",
    },
    {
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg3,
      bgColor: "bright-blue-slide",
    },
    {
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg4,
      bgColor: "pink-slide",
    },
    {
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg5,
      bgColor: "bright-violet-slide",
    },
    {
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg6,
      bgColor: "dark-blue-slide",
    },
    {
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg7,
      bgColor: "light-yellow-slide",
    },
    {
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg8,
      bgColor: "dark-yellow-slide",
    },
    {
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg9,
      bgColor: "red-slide",
    },
    {
      title: "Lorem Ipsum is simply dummy",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      picture: OurServiceSliderImg10,
      bgColor: "dark-bright-blue-slide",
    },
  ];
  const options = {
    loop: true,
    center: false,
    items: 3,
    margin: 15,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },

      // 768: {
      //   items: 1
      // },

      1200: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    const section = document.getElementsByClassName(
      "our-service-cards-wrap-container"
    )[0];
    const animateSection = () => {
      const bounding = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
        gsap.to(section, { rotationX: 0, duration: 0.8 });
      } else {
        gsap.to(section, { rotationX: 90, duration: 0.8 });
      }
    };
    animateSection();
    window.addEventListener("scroll", animateSection);
    return () => {
      window.removeEventListener("scroll", animateSection);
    };
  }, []);
  return (
    <section className="our-service-cards-wrap">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-5 our-service-cards-wrap-container">
            <div className="our-service-des- text-white">
              <h2 className="mb-3">Our Cards</h2>
              <a href="#" className="white-btn see-cards-btn">
                See all cards <i className="bi bi-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
          <div className="col-md-8">
            {cards.length > 0 && (
              <OwlCarousel className="owl-carousel owl-theme" {...options}>
                {cards.map((card, i) => (
                  <ServiceCard key={i} card={card} />
                ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceCards;
