import gsap from "gsap";
import React, { useEffect } from "react";
import savingcardsImgIcon from "../../assets/savingcards-img-icon.webp";
import goldInvestmentImgIcon from "../../assets/goldInvestment-img-icon.webp";
import stockTradingImgIcon from "../../assets/stockTrading-img-icon .webp";
import educationImgIcon from "../../assets/education-img-icon.webp";
import securityImgIcon from "../../assets/security-img-icon.webp";
import cardTrackingImgIcon from "../../assets/card-tracking-img-icon.webp";

const ServiceCardBelowSection = () => {
  useEffect(() => {
    const section = document.getElementsByClassName(
      "services-card-below-container"
    )[0];
    const animateSection = () => {
      const bounding = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
        gsap.to(section, { rotationX: 0, duration: 0.8 });
      } else {
        gsap.to(section, { rotationX: 90, duration: 0.8 });
      }
    };
    animateSection();
    window.addEventListener("scroll", animateSection);
    return () => {
      window.removeEventListener("scroll", animateSection);
    };
  }, []);
  return (
    <section className="services-card-below">
      <div className="container services-card-below-container">
        <h2 className="text-white text-center mb-5">Our Benefits</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="services-card-container text-white">
              <div className="services-icons-wrap w-100">
                <img src={savingcardsImgIcon} alt="" className="img-fluid" />
              </div>
              <div className="services-card-details">
                <h5>Savings & Cards</h5>
                <p className="mb-0">
                  Savings accounts, term deposits, debit cards and more.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-card-container text-white ">
              <div className="services-icons-wrap">
                <img src={goldInvestmentImgIcon} alt="" className="img-fluid" />
              </div>
              <div className="services-card-details">
                <h5>Gold Investments</h5>
                <p className="mb-0">
                  Invest in gold through partner depository programs.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-card-container text-white ">
              <div className="services-icons-wrap">
                <img src={stockTradingImgIcon} alt="" className="img-fluid" />
              </div>
              <div className="services-card-details">
                <h5>Stock & ETF Trading</h5>
                <p className="mb-0">
                  Invest in shares and electronic traded funds.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-card-container text-white ">
              <div className="services-icons-wrap">
                <img src={educationImgIcon} alt="" className="img-fluid" />
              </div>
              <div className="services-card-details">
                <h5>Education</h5>
                <p className="mb-0">
                  An engaging and comprehensive, visual money learning
                  experience for kids and adults alike.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-card-container text-white ">
              <div className="services-icons-wrap">
                <img src={securityImgIcon} alt="" className="img-fluid" />
              </div>
              <div className="services-card-details">
                <h5>Security</h5>
                <p className="mb-0">
                  Protected by industry leading best practice PCI-DSS standards
                  and banking partners.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-card-container text-white ">
              <div className="services-icons-wrap">
                <img src={cardTrackingImgIcon} alt="" className="img-fluid" />
              </div>
              <div className="services-card-details">
                <h5>Card Tracking</h5>
                <p className="mb-0">
                  Bank’d has developed Apple “Find My” certified trackers which
                  are attached to debit and credit cards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceCardBelowSection;
