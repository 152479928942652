import React from 'react';
const { REACT_APP_BASE_URL } = process.env;
const ServiceCard = ({ key, card }) => {
  return (
    <div className={`item ${card?.bgColor}`}>
      <img src={card?.picture} className='img-fluid w-100' />
    </div>
  )
}

export default ServiceCard;