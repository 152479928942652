import axios from 'axios';
import { toast } from "react-toastify";
import { start_loading, stop_loading } from '../loader/loaderAction'
import { GET_CARDS, GET_JOBS } from './userTypes';
const { REACT_APP_BASE_URL } = process.env;

const headers = {
  'Content-Type': 'application/json',
  "Accept":"application/json",
};
export const catch_errors_handle = (error, dispatch) => {
    if (error.response) {
      toast.error(error.response.data.message, {
        toastId: "errors1",
      });
    } else {
      toast.error(error.message, {
        toastId: "errors1",
      });
    }
  };
export const get_cards = async (dispatch,page=1,limit=10,search) => {
    dispatch(start_loading());
    try {
      const res = await axios.get(`${REACT_APP_BASE_URL}/active_cards?page=${page}&limit=${limit}${search?`&search=${search}`:''}`, { headers });
      if (res?.data?.status) {
        dispatch({type:GET_CARDS,payload:res?.data});
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      catch_errors_handle(error, dispatch);
    } finally {
      dispatch(stop_loading());
    }
}
export const get_jobs = async (dispatch,page=1,limit=10,search) => {
    dispatch(start_loading());
    try {
      const res = await axios.get(`${REACT_APP_BASE_URL}/active_careers?page=${page}&limit=${limit}${search?`&search=${search}`:''}`, { headers });
      if (res?.data?.status) {
        dispatch({type:GET_JOBS,payload:res?.data});
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      catch_errors_handle(error, dispatch);
    } finally {
      dispatch(stop_loading());
    }
}
export const apply_form = async (data,dispatch,navigate) => {
  dispatch(start_loading());
  try {
    headers["Content-Type"]="multipart/form-data";
    const res = await axios.post(`${REACT_APP_BASE_URL}admin/apply`, data, { headers });
    if (res?.data?.status) {
      toast.success('Successfully action.');
      navigate('/');
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    catch_errors_handle(error, dispatch);
  } finally {
    dispatch(stop_loading());
  }
}
export const get_in_touch = async (data,dispatch,navigate) => {
  dispatch(start_loading());
  try {
    headers["Content-Type"]="multipart/form-data";
    const res = await axios.post(`${REACT_APP_BASE_URL}admin/get_in_touch`, data, { headers });
    if (res?.data?.status) {
      toast.success('Successful action.');
      navigate('/');
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    catch_errors_handle(error, dispatch);
  } finally {
    dispatch(stop_loading());
  }
}