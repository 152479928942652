import React from 'react'
import ServiceCard from '../Shared/ServiceCard';

const CardsList = ({cards}) => {
  
  return (
    <section className='cards-wrapper-bg'>
      <div className='container'>
        <h2 className='mb-4 text-white text-center'>Cards</h2>
        <div className='row'>
          {cards.map((card, i) =>
            <div className='col-md-4'>
              <ServiceCard key={i} card={card}/>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default CardsList;