import React from "react";
import "../style.css";
import { Link, useLocation } from "react-router-dom";
import headerLogo from "../assets/header-bankd-Logo.webp";

const Header = () => {
  const location = useLocation();
  const items = [
    { name: "Home", url: "/" },
    { name: "About us", url: "/about-us" },
    { name: "Cards", url: "/cards" },
    { name: "Career", url: "/career" },
    { name: "Contact us", url: "/contact-us" },
  ];
  return (
    <div className="header-wrapper position-fixed top-0 w-100">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link to="/" className="navbar-brand header-logo-wrapper">
            <img src={headerLogo} alt="bankd-logo" className="img-fluid" />
          </Link>
          <button
            id="dropdownshow"
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav align-items-center">
              {items.map((item) => (
                <li
                  key={item?.name}
                  className="nav-item"
                  onClick={() =>
                    document.getElementById("dropdownshow").click()
                  }
                >
                  <Link
                    className={`nav-link ${
                      item?.url === "/contact-us" ? "header-contactus-btn" : ""
                    } ${
                      item?.url === location.pathname ? "active fw-bold" : ""
                    }`}
                    aria-current="page"
                    to={item?.url}
                  >
                    {item?.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
