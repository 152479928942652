import loaderReducer from './loader/loaderReducer';
import { userReducer } from './user/userReducer';
import { combineReducers } from 'redux';


const rootReducer = combineReducers({
  loader:loaderReducer,
  user:userReducer
});

export default rootReducer;
