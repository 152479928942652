import React from "react";
import Banner from "../Shared/banner";
import TrackerContent from "./content";
import TermsConditionsBanner from "../../assets/TermsConditions-banner.webp";

const TrackerInfo = () => {
  const data = {
    title: "Tracker Info",
    bgImage: TermsConditionsBanner,
  };
  return (
    <div>
      <Banner data={data} />
      <TrackerContent />
    </div>
  );
};

export default TrackerInfo;
