import React from 'react'
import { Link } from 'react-router-dom';
const { REACT_APP_BASE_URL } = process.env;
const CareerCard = ({key,card}) => {
  return (
    <Link to='/apply'>
      <div key={key} className='item'>
      <img src={card?.picture} className='img-fluid' />
      <div className='job-career-details'>
        <h5>{card?.heading}</h5>
      </div>
    </div>
    </Link>
  )
}

export default CareerCard;