import React from "react";
const TrackerContent = () => {
  return (
    <section className="terms-conditions-details">
      <div className="container text-white">
        <div className="border-bottom pb-3 mb-5">
          <h5 className="mb-3">Bank’d Card Tracker</h5>
          <p>
            coming soon !!!
          </p>
        </div>
      </div>
    </section>
  );
};

export default TrackerContent;
