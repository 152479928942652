import gsap from 'gsap';
import React, { useEffect } from 'react'

const ExploreKids = () => {
  useEffect(() => {
    const section = document.getElementsByClassName("explorekids-wrapper-container")[0];
    const animateSection = () => {
      const bounding = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
        gsap.to(section, { rotationX: 0, duration: 0.8 });
      } else {
        gsap.to(section, { rotationX: 90, duration: 0.8 });
      }
    };
    animateSection();
    window.addEventListener('scroll', animateSection);
    return () => {
      window.removeEventListener('scroll', animateSection);
    };
  }, []);
  return (
    <section className='explorekids-wrapper'>
      <div className='container explorekids-wrapper-container'>
        <div className='row'>
          <div className='col-lg-6 col-md-8'>
            <div className='explorekids-wrapper-des text-start'>
              <h2 className='text-white'>Their Own World</h2>
              <p className='text-white mt-3'>Kids can explore their own island world and enter their bank, stock exchange, gold mine, mint, home, school, airport, shopping mall, pirate ship and more. Each area includes educational and real world features for kids to not only spend money but learn, earn, invest and grow.</p>
              <a href='/about-us' className='d-inline-flex align-items-center justify-content-end white-btn w-auto'>About us <i className="bi bi-arrow-right ms-2"></i></a>
            </div>
          </div>
        </div>


      </div>
    </section>

  )
}

export default ExploreKids;