import gsap from "gsap";
import React, { useEffect } from "react";
import boringGirl from "../../assets/boring-girl.webp";

const BoringKids = () => {
  useEffect(() => {
    const section = document.getElementsByClassName(
      "boring-kids-wrapper-container"
    )[0];
    const animateSection = () => {
      const bounding = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
        gsap.to(section, { rotationY: 0, duration: 0.8 });
      } else {
        gsap.to(section, { rotationY: 90, duration: 0.8 });
      }
    };
    animateSection();
    window.addEventListener("scroll", animateSection);
    return () => {
      window.removeEventListener("scroll", animateSection);
    };
  }, []);
  return (
    <section className="boring-kids-wrapper">
      <div className="container boring-kids-wrapper-container">
        <div className="row">
          <div className="col-md-7">
            <div className="boring-kids-des- text-white">
              <h2>Not Another Boring Kids Banking App…</h2>
              <p className="my-3">
                There are many banking providers offering bank accounts and apps
                designed for kids but there is one major problem, they are all
                boring! Kids need to engage and learn and a colorful debit card
                isn’t going to help.
              </p>
              <a href="/apply" className="white-btn register-interest-btn">
                Register Interest
              </a>
            </div>
          </div>
          <div className="col-md-5">
            <img src={boringGirl} alt="boring-girl" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoringKids;
