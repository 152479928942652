import React from "react";
import Banner from "../Shared/banner";
import TermsContent from "./content";
import TermsConditionsBanner from "../../assets/TermsConditions-banner.webp";

const TermsConditions = () => {
  const data = {
    title: "Terms and Policy",
    bgImage: TermsConditionsBanner,
  };
  return (
    <div>
      <Banner data={data} />
      <TermsContent />
    </div>
  );
};

export default TermsConditions;
