import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import Input, { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en';
import 'react-phone-number-input/style.css'
const PhoneNumberInput=({value,name,onChange})=>{
      const [country, setCountry] = useState('US')
      const CountrySelect = ({ value, onChange, labels, ...rest }) => (
        <Dropdown className="custom-country-code">
            <Dropdown.Toggle variant="default" className="form-control">
               +{getCountryCallingCode(country)}
            </Dropdown.Toggle>
    
            <Dropdown.Menu>
               {getCountries().map((country) => (<Dropdown.Item key={country} onClick={()=>setCountry(country)}>
                  {labels[country]}(+{getCountryCallingCode(country)})
               </Dropdown.Item>
            ))}
           </Dropdown.Menu>
       </Dropdown>
      );
    return <div className="d-flex align-items-center input-group">
        <CountrySelect
            labels={en}
            value={country}
            onChange={setCountry}
        />
        <Input
            defaultCountry={country}
            className="form-control"
            value={value}
            onChange={(value)=>onChange(formatPhoneNumberIntl(value),'phone')}
        />
    </div> 
}
export default PhoneNumberInput;