import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import growingImg from "../../assets/growing-img.webp";

const GrowingMoney = () => {
  useEffect(() => {
    const playAnimation = () => {
      gsap.registerPlugin(ScrollTrigger);
      const tl = gsap.timeline();
      tl.to(".my-image", {
        rotationY: 30,
        duration: 2,
        ease: "power2.inOut",
      });

      tl.to(".my-image", {
        rotationY: 0,
        duration: 2,
        ease: "power2.inOut",
        onComplete: () => {
          clearInterval(intervalID);
          playAnimation();
        },
      });
    };

    playAnimation();
    const intervalID = setInterval(() => {
      playAnimation();
    }, 4000);
    return () => clearInterval(intervalID);
  }, []);
  useEffect(() => {
    const section = document.getElementsByClassName(
      "growing-money-wrapper-container"
    )[0];
    const animateSection = () => {
      const bounding = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
        gsap.to(section, { rotationX: 0, duration: 0.8 });
      } else {
        gsap.to(section, { rotationX: 90, duration: 0.8 });
      }
    };
    animateSection();
    window.addEventListener("scroll", animateSection);
    return () => {
      window.removeEventListener("scroll", animateSection);
    };
  }, []);
  return (
    <section className="growing-money-wrapper">
      <div className="container growing-money-wrapper-container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img src={growingImg} className="img-fluid my-image" />
          </div>
          <div className="col-md-6">
            <div className="growing-money-des- text-white">
              <h2>Growing Their Money</h2>
              <p className="my-3">
                Kids can visit their bank rooftop to plant a “money tree”, aka
                Term Deposit. Kids can actually earn interest and watch it grow
                on their tree. Cut it down early and the interest is lost.
              </p>
              <a href="/apply" className="white-btn">
                Register Interest
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GrowingMoney;
