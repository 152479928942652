import gsap from "gsap";
import React, { useEffect } from "react";
import entrepreneurs from "../../assets/entrepreneurs.webp";

const Enterpernures = () => {
  useEffect(() => {
    const section = document.getElementsByClassName(
      "enterpernures-bg-container"
    )[0];
    const animateSection = () => {
      const bounding = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
        gsap.to(section, { rotationX: 0, duration: 0.8 });
      } else {
        gsap.to(section, { rotationX: 90, duration: 0.8 });
      }
    };
    animateSection();
    window.addEventListener("scroll", animateSection);
    return () => {
      window.removeEventListener("scroll", animateSection);
    };
  }, []);
  return (
    <section className="enterpernures-bg-wrapper">
      <div className="container enterpernures-bg-container">
        <div className="row">
          <div className="col-md-7">
            <div className="enterpernures-des- text-white">
              <h2 className="text-capitalize">
                Built by one of Australia’s top tech entrepreneurs.
              </h2>
              <p className="mb-0 mt-3">
                Bank’d is the brain child of Brad Illich, a multi award winning
                entrepreneur with over 25 years experience in the technology
                industry. His previous startup NewBook is a global hospitality
                industry success story and processes billions of dollars worth
                of bookings per year. With four young children of his own, Brad
                wanted to create a solution to help families preserve and grow
                wealth from generation to generation.
              </p>
            </div>
          </div>
          <div className="col-md-5">
            <img
              src={entrepreneurs}
              alt="enterpernures"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Enterpernures;
