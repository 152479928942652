import gsap from "gsap";
import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import makeMoneyIpad from "../../assets/make-money-ipad.webp";

const MakeMoney = () => {
  useEffect(() => {
    const playAnimation = () => {
      gsap.registerPlugin(ScrollTrigger);
      const tl = gsap.timeline();
      tl.to(".my-image", {
        rotationY: 30,
        duration: 2,
        ease: "power2.inOut",
      });

      tl.to(".my-image", {
        rotationY: 0,
        duration: 2,
        ease: "power2.inOut",
        onComplete: () => {
          clearInterval(intervalID);
          playAnimation();
        },
      });
    };

    playAnimation();
    const intervalID = setInterval(() => {
      playAnimation();
    }, 4000);
    return () => clearInterval(intervalID);
  }, []);
  useEffect(() => {
    const section = document.getElementsByClassName(
      "making-money-wrapper-container"
    )[0];
    const animateSection = () => {
      const bounding = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bounding.top < windowHeight * 0.75 && bounding.bottom > 0) {
        gsap.to(section, { rotationX: 0, duration: 0.8 });
      } else {
        gsap.to(section, { rotationX: 90, duration: 0.8 });
      }
    };
    animateSection();
    window.addEventListener("scroll", animateSection);
    return () => {
      window.removeEventListener("scroll", animateSection);
    };
  }, []);
  return (
    <section className="making-money-wrapper">
      <div className="container making-money-wrapper-container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <img src={makeMoneyIpad} className="my-image img-fluid" />
          </div>
          <div className="col-lg-7 col-md-6">
            <div className="making-money-des- text-white">
              <h2>Making Money Tangible Again</h2>
              <p className="my-3">
                Its easy to forget that kids these days were born with digital
                payments being the norm. Its harder to understand the value of
                money when you never see it. This is why Bank’d presents digital
                money as physical currency to help kids visually see how much
                they have and how much they spend.
              </p>
              <a href="/apply" className="white-btn register-interest-btn">
                Register Interest
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MakeMoney;
